var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('x-data-table',{staticClass:"data-table_type_documents",attrs:{"headers":_vm.preparedHeaders,"items":_vm.preparedDocuments,"use-item-slot":true,"checkbox":_vm.selectedAll,"show-select":true,"use-header-checkbox":true,"pagination-props":_vm.paginationProps},on:{"checkbox":() => {
      _vm.selectedAll = !_vm.selectedAll || _vm.selectedRows.some((state) => !state);
      _vm.selectedRows = Array(_vm.docsLength).fill(_vm.selectedAll);
    }},scopedSlots:_vm._u([{key:"item",fn:function({ item: { index, item } }){return [_c('tr',{class:[
        'data-table__text',
        'data-table__text_cursor_default',
        _vm.selectedRows[index] && 'data-table__text_active_base',
      ]},[_c('td',[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"hide-details":""},on:{"change":_vm.checked},model:{value:(_vm.selectedRows[index]),callback:function ($$v) {_vm.$set(_vm.selectedRows, index, $$v)},expression:"selectedRows[index]"}})],1),_vm._l((item),function(value,header){return _c('td',{key:`${header}`,class:_vm.tdClassesByHeaders[header]},[(header.endsWith('type'))?_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(" "+_vm._s(_vm.humanDocTypeByValue[value[0]] || value[0])+" "+_vm._s(value[1] && `№ ${value[1]}`)+" ")]),_c('span',{staticClass:"secondary--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(value[2]))+" ")])]):(header.endsWith('analytics'))?[_vm._v(" "+_vm._s(value || "—")+" ")]:(header.endsWith('theme'))?_c('span',{class:[
            value &&
              [
                'd-block',
                'align-center',
                'justify-center',
                'icon',
                'icon_size_md',
                'icon_color_base',
                'rounded',
                'background--text',
                'text--lighten-5',
                'text-uppercase',
                'doc-themes'
              ].join(' '),
          ]},[_vm._v(" "+_vm._s(value || "—")+" ")]):(header.startsWith('sum'))?_c('span',{staticClass:"d-block text-right"},[_vm._v(" "+_vm._s(_vm._f("prepareMoney")(value))+" ")]):_vm._e()],2)})],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }